<template>
  <v-dialog v-model="dialog" max-width="1000" persistent transition="dialog-bottom-transition">
    <v-card v-if="!addDnsInfo" tile>
      <v-toolbar flat dark color="primary">
        <!-- close btn -->
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <!-- title -->
        <v-toolbar-title>Configurar DNS Record</v-toolbar-title>
        <v-spacer></v-spacer>

        <!-- save -->
        <v-toolbar-items>
          <v-btn dark text @click="save">
            Salvar
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <!-- variables -->
      <v-container>
        <v-form ref="form">
          <v-alert type="info">
            Copie e cole o conteúdo TSV conforme a sintaxe utilizando tabs.
          </v-alert>
          <v-row>
            <v-col cols="12">
              <v-jsoneditor v-model="tsv" :plus="false" :options="options" />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>

    <!-- add dns info -->
    <v-card v-else tile>
      <v-toolbar flat dark>
        <!-- close btn -->
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <!-- title -->
        <v-toolbar-title>Configurar DNS Record</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-alert border="bottom" colored-border type="warning" elevation="2">
        <h4>1. Faça login na conta do registrador</h4>
        <br />
        <p>Remova estes nameservers:</p>
        <p v-for="(item, index) in zoneList.original_name_servers" :key="index">
          <v-chip pill>{{ item }}</v-chip>
        </p>
        <h4>2. Substitua pelos nameservers da Cloudflare</h4>
        <br />
        <p v-for="(item, index) in zoneList.name_servers" :key="index">
          <v-chip pill>{{ item }}</v-chip>
        </p>
        <br />
        <p>
          <i>As alterações podem demorar até 24 horas para serem atualizadas.</i>
        </p>
      </v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import { MUTATE_ADD_DNS_RECORD } from '@/modules/accounts/graphql'

export default {
  props: {
    dialog: Boolean,
    accountId: String,
    zoneList: Object
  },
  data: () => ({
    options: { mode: 'code', mainMenuBar: false },
    tsv: '',
    addDnsInfo: false,
    dnsInfo: {
      name_servers: [],
      original_name_servers: []
    }
  }),
  methods: {
    async save() {
      try {
        await this.$apollo.mutate({
          mutation: MUTATE_ADD_DNS_RECORD,
          variables: {
            id: this.accountId,
            tsv: this.parseTsv(this.tsv)
          }
        })
        this.addDnsInfo = true
      } catch (err) {
        console.log(err)
        this.$snackbar({ message: 'Falha ao adicionar DNS Record' })
      }
    },
    parseTsv(str) {
      str = str.replace(/\s{2,}/g, '\t')
      str = str.replace(/\t/g, '\t')
      str = str.toString().replace(/(\r\n|\n|\r)/g, '\n')
      return str
    }
  }
}
</script>
